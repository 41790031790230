import React from "react";
import { ThemeProvider } from "styled-components";
import Helmet from "react-helmet";

import Header from "../component/header";
import { LIGHT } from "../styles/themes";
import { NoMarginBody } from "../component/aboutus/Common";

import Building from "../component/aboutus/Building";
import Values from "../component/aboutus/Values";
import Team from "../component/aboutus/Team";
import Investors from "../component/aboutus/Investors";
import FAQ from "../component/FAQ";
import CTABlock from "../component/CTABlock";
import Footer from "../component/Footer";
import FixedWidth from "../component/design-system/FixedWidth";

const AboutPage = () => {
    return (
        <ThemeProvider theme={LIGHT}>
            <NoMarginBody />
            <Helmet>
                <title>TestBox</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
            </Helmet>
            <FixedWidth>
                <Header />
            </FixedWidth>
            <Building />
            <Values />
            <Team />
            <Investors />
            <FAQ />
            <CTABlock />
            <Footer />
        </ThemeProvider>
    );
};

export default AboutPage;
