import React from "react";
import styled from "styled-components";

const PlayWrapper = styled.div`
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    z-index: 50;
    cursor: pointer;
`;

export default function PlayButton({ onClick }) {
    return (
        <PlayWrapper onClick={onClick}>
            <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="40" cy="40" r="40" fill="white" />
                <path
                    d="M35.0298 32.671C35.0207 31.0182 36.9067 30.0683 38.2293 31.0596L50.1145 39.9681C51.1839 40.7697 51.1813 42.3744 50.1094 43.1726L38.3221 51.9493C37.0068 52.9286 35.1367 51.996 35.1276 50.3562L35.0298 32.671Z"
                    fill="#15808D"
                />
            </svg>
        </PlayWrapper>
    );
}
