import React from "react";
import { VerticalBlock } from "../Block";
import styled from "styled-components";

import * as fonts from "../../styles/fonts";
import * as colors from "../../styles/colors";
import FlexImg from "../design-system/FlexImg";

import breakpoints from "../design-system/Breakpoints";
import AndyPortrait from "../../assets/team/Andy.jpg";
import ElainePortrait from "../../assets/team/Elaine_Headshot.png";
import IsabellaPortrait from "../../assets/team/IsabellaChiu.jpg";
import JakePortrait from "../../assets/team/Jake.jpg";
import JamesPortrait from "../../assets/team/jtanner_portrait.png";
import PeterPortrait from "../../assets/team/Peter.jpg";
import SamPortrait from "../../assets/team/Sam.jpg";
import KinnariPortrait from "../../assets/team/KinnariDP.jpg";
import MeganPortrait from "../../assets/team/megan.jpg";

const TeamMembers = [
    {
        image: SamPortrait,
        name: "Sam Senior",
        title: "Founder",
        bio: (
            <>
                <p>
                    Hi! I’m Sam. I’m from a small town in regional Australia and
                    now re-designing the software industry. I’ve been in the US
                    for over 5 years (previously at Bain & Co) and am obsessed
                    with the incredible landscapes.
                </p>
                <p>
                    Catch me riding bikes, watching cricket, playing with any
                    dogs nearby, and trying new croissants whenever possible.
                </p>
            </>
        ),
    },
    {
        image: PeterPortrait,
        name: "Peter Holland",
        title: "Founder",
        bio: (
            <>
                <p>
                    Hello, I'm Peter :) I like solving problems (especially with
                    code), optimizing all the things, getting out of peoples
                    way, and eating delicious food.
                </p>
                <p>
                    It's not uncommon to find me riding a bike up some massive
                    mountain, watching The Expanse, attempting to express
                    emotion by modulating the tone of my voice, or eating tasty
                    food.
                </p>
            </>
        ),
    },
    {
        image: KinnariPortrait,
        name: "Kinnari Parikh",
        title: "Product Design Lead",
        bio: (
            <>
                <p>
                    Hey, I am Kinnari!
                    <br />I am a Product designer based in Mumbai. I specialise
                    in finding simple solutions to complex design problems.
                    Crafting an experience that brings joy to the user, is what
                    I love about my work.
                </p>

                <p>
                    Taking a long walk, doodling or trying out a new recipe is
                    how I like to unwind. Also, I never say no to a good cup of
                    coffee :)
                </p>
            </>
        ),
    },
    {
        image: JakePortrait,
        name: "Jake Wood",
        title: "Fullstack Engineer",
        bio: (
            <>
                <p>
                    Hi, I’m Jake, a full-stack software engineer and digital
                    nomad. While I do work across the stack, my specialties are
                    front-end and API design. I actually have a dual major in
                    computer science and entrepreneurship, making me a
                    double-threat for the start-up scene! (Kidding)
                </p>
                <p>
                    In my spare time, I enjoy hiking, roller skating, and rock
                    climbing.
                </p>
            </>
        ),
    },
    {
        image: JamesPortrait,
        name: "James Tanner",
        title: "Fullstack Engineer",
        bio: (
            <>
                <p>
                    Hi Folks, I'm James. My background is in art, automation,
                    python and react.js development. I came to TestBox as a
                    full-stack developer and I'm very excited to be part of the
                    initial team that gets to build the stack from the bottom
                    up.
                </p>
                <p>
                    In my spare time, I enjoy listening to litrpg audiobooks,
                    long walks, painting, and hacking on small programming
                    projects.
                </p>
            </>
        ),
    },
];

const Consultants = [
    {
        image: IsabellaPortrait,
        name: "Isabella Chiu",
        title: "Strategy & Ops Consultant",
        bio: (
            <>
                <p>
                    Hi, I'm Isabella! I'm a proud Canadian with extensive tech
                    strategy experience. I'm passionate about making complex
                    processes easier and more efficient. There's nothing in my
                    life I haven’t tried to optimize.
                </p>
                <p>
                    When I'm not making spreadsheets, I enjoy drawing (I
                    published a kids' book, Let's Get Boba!), cooking, and
                    spending time with friends and family.
                </p>
            </>
        ),
    },
];

const BoardMembers = [
    {
        image: ElainePortrait,
        name: "Elaine Zelby",
        title: "Partner at SignalFire",
        bio: null,
    },
    {
        image: AndyPortrait,
        name: "Andrew Nguyen",
        title: "Board member and DEI Advisor",
        bio: null,
    },
    {
        image: MeganPortrait,
        name: "Megan Niedermeyer",
        title: "General Counsel",
        bio: null,
    },
];

const Heading = styled.h3`
    font-family: ${fonts.HEADER};
    font-size: 20px;
    color: ${colors.textColors[0]};
    line-height: 25px;
    font-weight: 800;
    margin: 24px 0 8px 0;
`;

const Title = styled.div`
    font-family: ${fonts.fontFamilies.text};
    font-size: ${fonts.textSize};
    color: ${colors.textColors[0]};
`;

const TeamWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.small}) {
        justify-content: left;
    }
`;

const MemberWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: ${breakpoints.small}) {
        width: calc(25% - 30px);
        padding: 15px;
        padding-bottom: 50px;
    }
    @media (max-width: ${breakpoints.small}) {
        width: calc(50% - 10px);
        padding: 5px;
        padding-bottom: 100px;
    }
`;

const TeamImage = styled(FlexImg)`
    border-radius: 8px;
    object-fit: cover;
    height: 230px;

    ${MemberWrapper}:hover && {
        border: 2px solid ${colors.TEAL};
        box-sizing: border-box;
        filter: drop-shadow(4px 8px 44px rgba(0, 42, 43, 0.4));
    }
`;

const PedalWrapper = styled.div`
    display: none;
    position: absolute;
    left: calc(100% - 35px);
    top: -10px;
    z-index: 50;

    ${MemberWrapper}:hover && {
        display: block;
    }
`;

const Text = styled.div`
    font-size: 1.125rem;
`;

const Pedal = () => {
    return (
        <PedalWrapper>
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 10.1522V29.5272C0 31.1572 0.988997 32.6241 2.49999 33.2353L17.6623 39.3688C19.7403 40.512 22.684 39.8451 23.8961 39.3688L38.2166 33.0152C39.301 32.5341 40 31.4593 40 30.273V9.43486C40 8.23415 39.2841 7.1491 38.1802 6.67675L24.4156 0.786969C21.9221 -0.356196 18.7013 -0.00688043 17.6623 0.310665L2.5 6.44414C0.989 7.05537 0 8.5223 0 10.1522Z"
                    fill="#86C3AF"
                />
            </svg>
        </PedalWrapper>
    );
};

const TeamMember = ({ member, consultant }) => {
    return (
        <MemberWrapper>
            <Pedal />
            {consultant ? <Heading style={{ marginTop: -33 }}>External Consultants</Heading> : null}
            <TeamImage src={member.image} />
            <Heading>{member.name}</Heading>
            <Title>{member.title}</Title>
            {member.bio ? <Text>{member.bio}</Text> : null}
        </MemberWrapper>
    );
};

export const Team = () => {
    // Sort the team alphabetical by first name
    TeamMembers.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    // Sort the board alphabetical by first name
    BoardMembers.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return (
        <VerticalBlock
            id="team"
            header="The awesome people that make TestBox..."
            buttonColor="joinWaitlist"
            buttonText="Join us!"
            buttonLink="https://coda.io/@sam-senior/careers-at-testbox"
        >
            <Heading>Our Team</Heading>
            <TeamWrapper>
                {TeamMembers.map((tm) => (
                    <TeamMember member={tm} key={tm.name} />
                ))}
                {Consultants.map((tm) => (
                    <TeamMember member={tm} key={tm.name} consultant={true} />
                ))}
            </TeamWrapper>
            <Heading>Our Board</Heading>
            <TeamWrapper>
                {BoardMembers.map((tm) => (
                    <TeamMember member={tm} key={tm.name} />
                ))}
            </TeamWrapper>
        </VerticalBlock>
    );
};

export default Team;
