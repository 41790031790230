import React from "react";
import styled from "styled-components";

import * as colors from "../../styles/colors";

import { H1 } from "./Common";
import FixedWidth from "../design-system/FixedWidth";
import HeroRow from "../design-system/HeroRow";
import { HeroColumn } from "../home/Hero";
import breakpoints from "../design-system/Breakpoints";
import Video from "./Video";
import { fontFamilies } from "../../styles/fonts";

const StyledRow = styled(HeroRow)`
    @media (max-width: ${breakpoints.small}) {
        position: relative;
        padding: 0px 0 50px 0;
        margin-top: 40px;
    }
    margin-bottom: 130px;
`;

const StyledHero = styled(HeroColumn)`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${breakpoints.small}) {
        z-index: 500;
    }
`;

const Text = styled.p`
    font-family: ${fontFamilies.text};
    font-size: 1.125rem;
    line-height: 136%;
    color: ${colors.textColors[1]};
    max-width: 80%;
`;

export const Building = () => {
    return (
        <FixedWidth>
            <StyledRow id="mission">
                <StyledHero>
                    <H1 style={{ color: '#24695C', zIndex: 10 }}>
                        We are building the future of software buying!
                    </H1>
                    <Text>
                        We started TestBox after seeing too many painful
                        software buying experiences. We’re making the evaluation
                        process all about the customer and product, not sales
                        and marketing.
                    </Text>
                </StyledHero>
                <Video />
            </StyledRow>
        </FixedWidth>
    );
};

export default Building;
