import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import * as colors from "../../styles/colors";
import * as fonts from "../../styles/fonts";
import breakpoints from "../design-system/Breakpoints";

// kills all outter margins
export const NoMarginBody = createGlobalStyle`
body {
  margin: 0;
}
`;

export const FlexImg = styled.img`
    max-width: 100%;
`;

// creates side by side elements
export const LeftRightWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

// creates side by side elements with a medium bg
export const LeftRightWrapperMedium = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${colors.LIGHTER_TEAL};
`;

// creates side by side elements with a dark bg
export const LeftRightWrapperDark = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${colors.DARK_GREEN};
`;

// creates vertical elements
export const UpDownWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

// vertical element
export const UpDownDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

// no padding flex box
export const UnPaddedDiv = styled.div`
    display: flex;
`;

// slight padding except on bottom
export const PaddedDiv = styled.div`
    display: flex;
    padding: 5% 5% 0% 5%;
`;

// big text
export const H1 = styled.h1`
    font-family: ${fonts.fontFamilies.header};
    color: ${colors.BLACK};
    font-weight: 800;
    font-size: 3rem;
    line-height: 116%;
    max-width: 80%;
`;
export const H2 = styled.h2`
    font-family: ${fonts.fontFamilies.header};
    color: ${colors.BLACK};
    font-weight: 700;

    @media (max-width: ${breakpoints.small}) {
        font-size: ${fonts.HEADER_SIZES.mobile};
        line-height: ${fonts.lineSizes.mobile};
    }
    @media (min-width: ${breakpoints.small}) {
        font-size: ${fonts.HEADER_SIZES.desktop};
        line-height: ${fonts.lineSizes.desktop};
    }
`;

// spacer between elements
export const Spacer = styled.div`
    display: flex;
    padding: 5% 0% 0% 0%;
`;

export const TitleBannerWithButton = (props) => {
    return (
        <LeftRightWrapper
            style={{
                backgroundColor: props.background,
                padding: "0% 5% 0% 5%",
            }}
        >
            <UnPaddedDiv style={{ flex: 0.4, justifyContent: "left" }}>
                <H2>{props.headerText}</H2>
            </UnPaddedDiv>
            <UnPaddedDiv style={{ flex: 0.7 }}></UnPaddedDiv>
            <UnPaddedDiv
                style={{ alignItems: "center", justifyContent: "center" }}
            >
                <button
                    style={{
                        border: "none",
                        color: "white",
                        backgroundColor: colors.TEAL,
                        height: "40px",
                        width: "150px",
                    }}
                >
                    {props.buttonText}
                </button>
            </UnPaddedDiv>
        </LeftRightWrapper>
    );
};
