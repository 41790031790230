import React from "react";
import styled from "styled-components";

import * as colors from "../../styles/colors";
import breakpoints from "../design-system/Breakpoints";

import SignalFireLogo from "../../assets/signalfire_logo.png";
import FirstMinuteLogo from "../../assets/firstminute_logo.png";
import LifelineLogo from "../../assets/lifeline_logo_trimmed.png";
import BasecampLogo from "../../assets/basecamp_logo.png";
import { fontFamilies } from "../../styles/fonts";

const InvestorsContainer = styled.div`
    background-color: ${colors.textColors[0]};
    z-index: 15;
    display: flex;
    align-items: center;

    @media (max-width: ${breakpoints.small}) {
        flex-direction: column;
        padding: 80px 32px 80px 32px;
    }

    @media (min-width: ${breakpoints.small}) {
        margin-left: 150px;
        padding: 60px;
    }
`;

const Header = styled.h3`
    font-family: ${fontFamilies.header};
    font-size: 32px;
    color: ${colors.LIGHT_TEAL};
`;

const InvestorsWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

const FlexImgWrap = styled.div`
    flex: 1;
    text-align: center;
`;
const FlexImage = styled.img`
    max-width: 100%;
    max-height: 180px;
`;

const FlexImg = ({ src, alt }) => (
    <FlexImgWrap>
        <FlexImage src={src} alt={alt} />
    </FlexImgWrap>
);

const BackgroundHack = styled.div`
    position: relative;
`;

const MintBackground = styled.div`
    background-color: ${colors.BEIGE};
    height: 50%;
    z-index: -10;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
`;

export const Investors = () => {
    return (
        <BackgroundHack>
            <InvestorsContainer id="investors">
                <Header>Our Investors</Header>
                <InvestorsWrapper>
                    <FlexImg src={SignalFireLogo} alt="signalfire" />
                    <FlexImg src={FirstMinuteLogo} alt="firstminute" />
                    <FlexImg src={LifelineLogo} alt="lifeline" />
                    <FlexImg src={BasecampLogo} alt="basecamp" />
                </InvestorsWrapper>
            </InvestorsContainer>
            <MintBackground></MintBackground>
        </BackgroundHack>
    );
};

export default Investors;
