import React from "react";
import * as colors from "../../styles/colors";
import breakpoints from "../design-system/Breakpoints";
import * as icons from "./icons";
import * as fonts from "../../styles/fonts";

import Block, { H2 } from "../Block";
import styled from "styled-components";

const ValuesWrapper = styled.div`
    flex: 1;
    display: grid;
    grid-gap: 1px;

    grid-template-areas:
        "1 2"
        "3 4"
        "5 6";
    grid-template-columns: 1fr 1fr;
`;

const ValueCell = styled.div`
    display: flex;
    background-color: ${colors.SUBTLE_BLUE};
    font-size: 1.125rem;

    @media (min-width: ${breakpoints.small}) {
        padding: 30px;
    }
    @media (max-width: ${breakpoints.small}) {
        padding: 15px;
        flex-direction: column;
    }
`;

const Heading = styled.h3`
    font-family: ${fonts.HEADER};
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 0;
`;

const Text = styled.p`
    font-family: ${fonts.fontFamilies.text};
    font-size: ${fonts.textSize};
    line-height: 132%;
    color: ${colors.textColors[1]};
`;

const StyledBlock = styled(Block)`
    && ${H2} {
        z-index: 5;
    }
`;

const ValueBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`;

export const Values = () => {
    return (
        <StyledBlock
            backgroundColor={colors.SUBTLE_BLUE}
            header="And are driven by our values, every day!"
        >
            <ValuesWrapper>
                <ValueCell>
                    <icons.Team />
                    <ValueBody>
                        <Heading>Team comes first...Always!</Heading>
                        <Text>
                            Our people are the highest priority at TestBox. We
                            are building systems for phenomenal career growth,
                            whether you want to move swiftly into people
                            management or crunch on code or design each day.
                        </Text>
                    </ValueBody>
                </ValueCell>
                <ValueCell>
                    <icons.SmileyQuote />
                    <ValueBody>
                        <Heading>
                            Diversity in background &amp; thought is critical.
                        </Heading>
                        <Text>
                            We know that diverse teams are happier and more
                            successful. We actively strive towards diversity
                            both in our workplace and our product so that our
                            team and customers have a safe space to bring their
                            full selves.
                        </Text>
                    </ValueBody>
                </ValueCell>
                <ValueCell>
                    <icons.Question />
                    <ValueBody>
                        <Heading>
                            Approach problems with curiosity &amp; empathy
                        </Heading>
                        <Text>
                            As we tackle problems, we strive to really
                            understand beneath the surface and build empathy in
                            order to truly solve for the right issues, rather
                            than accepting what we observe at face value.
                        </Text>
                    </ValueBody>
                </ValueCell>
                <ValueCell>
                    <icons.Beaker />
                    <ValueBody>
                        <Heading>Always experiment &amp; learn</Heading>
                        <Text>
                            We are always experimenting in order to learn and
                            get closer to the best solution with new and radical
                            ideas and fast iterations. We also believe in
                            reflecting, learning from feedback, and exiting bad
                            decisions quickly.
                        </Text>
                    </ValueBody>
                </ValueCell>
                <ValueCell>
                    <icons.Hiking />
                    <ValueBody>
                        <Heading>Make it happen.</Heading>
                        <Text>
                            If anyone finds a new idea to pursue or a problem
                            that is unaddressed, we want each team member to
                            feel empowered to step up to take responsibility and
                            just make it happen.
                        </Text>
                    </ValueBody>
                </ValueCell>
                <ValueCell>
                    <icons.HeartHand />
                    <ValueBody>
                        <Heading>
                            Be kind &amp; make a substantial positive impact
                        </Heading>
                        <Text>
                            Kindness can require strength and courage but it
                            always makes a situation better - we can all look
                            after ourselves and others better. We love to
                            support groups that uplift society, other humans and
                            the environment.
                        </Text>
                    </ValueBody>
                </ValueCell>
            </ValuesWrapper>
        </StyledBlock>
    );
};

export default Values;
