import React, { useRef, useState } from "react";
import styled from "styled-components";
import { breakpointsV2 } from "../design-system/Breakpoints";
import PlayButton from "../PlayButton";

const Container = styled.div`
    position: relative;

    @media (min-width: ${breakpointsV2.small}) {
        margin-right: 140px;
    }
    @media (max-width: ${breakpointsV2.small}) {
        width: 100%;
    }
`;

const HeroVideo = styled.div`
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    z-index: 2;
    display: flex;
    justify-content: center;

    @media (min-width: ${breakpointsV2.small}) {
        width: 535px;
        height: 603px;
    }
    @media (max-width: ${breakpointsV2.small}) {
        width: 100%;
    }
`;

const Video = styled.video`
    @media (max-width: ${breakpointsV2.small}) {
        width: 100vw;
    }
`;

const TopSparkleWrap = styled.div`
    position: absolute;
    top: 41px;
    left: -17px;
    z-index: 10;
`;
const TopSparkle = () => (
    <TopSparkleWrap>
        <svg
            width="34"
            height="36"
            viewBox="0 0 34 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 9.32763V26.3839C0 27.9739 0.94177 29.413 2.39887 30.0494L14.7214 35.4319C16.4533 36.4608 18.9068 35.8606 19.9171 35.4319L31.6357 29.8179C32.677 29.319 33.3395 28.2669 33.3395 27.1123V8.62852C33.3395 7.45882 32.6597 6.39577 31.5978 5.90516L20.3501 0.708273C18.2718 -0.320576 15.5873 -0.00619191 14.7214 0.279599L2.39888 5.66207C0.941773 6.29853 0 7.73759 0 9.32763Z"
                fill="#86C3AF"
            />
        </svg>
    </TopSparkleWrap>
);

const BottomSprakleWrap = styled.div`
    position: absolute;
    bottom: -170px;
    right: -80px;
    z-index: 1;

    @media (max-width: ${breakpointsV2.small}) {
        right: 0;
    }
`;
const BottomSparkle = () => (
    <BottomSprakleWrap>
        <svg
            width="237"
            height="273"
            viewBox="0 0 237 273"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M52 52.9002V145.011C52 154.555 57.6554 163.192 66.4035 167.009L133.688 196.362C143.299 202.063 156.913 198.737 162.519 196.362L223.359 167.252C231.694 163.264 237 154.844 237 145.603V52.543C237 43.1812 231.556 34.6738 223.056 30.7513L164.922 3.9252C153.39 -1.77661 138.494 -0.0343151 133.688 1.54952L66.4035 30.9024C57.6554 34.7187 52 43.3559 52 52.9002Z"
                fill="#86C3AF"
            />
            <path
                d="M0 221.457V250.946C0 255.717 2.82531 260.034 7.19662 261.943L29.847 271.837C33.3584 273.923 38.3329 272.706 40.3812 271.837L60.7793 262.065C64.9443 260.069 67.5947 255.861 67.5947 251.243V221.279C67.5947 216.6 64.8753 212.348 60.6279 210.385L41.2591 201.436C37.0454 199.35 31.6027 199.987 29.847 200.567L7.19663 210.461C2.82532 212.37 0 216.687 0 221.457Z"
                fill="#86C3AF"
            />
        </svg>
    </BottomSprakleWrap>
);

export default function VideoMain() {
    const video = useRef();
    const [isPaused, setPaused] = useState(true);

    return (
        <Container>
            <TopSparkle />
            <HeroVideo>
                {isPaused ? (
                    <PlayButton
                        onClick={() => {
                            video.current.play();
                            setPaused(false);
                        }}
                    />
                ) : null}
                <Video height="100%" controls ref={video}>
                    <source
                        src="https://dukuoou025q3p.cloudfront.net/video.mp4"
                        type="video/mp4"
                    />
                </Video>
            </HeroVideo>
            <BottomSparkle />
        </Container>
    );
}
